<template>
  <c-box>
    <div v-if="isDevelopment" v-chakra my="50px" border-width="0" border-top-width="3px" text-align="center">
      <pre>BATAS</pre>
    </div>
    <template v-if="questions != null">
      <template v-for="(item, idx) in questions">
        <QShortAnswer
          ref="answer"
          v-if="item.answerType === 'short_answer'"
          :key="`short-${item.id}`"
          :question="item.question"
          :is-required="item.isRequired"
          :subtext="item.subtext"
          v-model="item.answer"
          :id="`dinamis-form-${idx}`"
        />
        <QAnswerDropdown
          ref="answer"
          v-else-if="item.answerType === 'dropdown'"
          :key="`dropdown-${item.id}`"
          :question="item.question"
          :selections="item.answerContent"
          :is-required="item.isRequired"
          :subtext="item.subtext"
          v-model="item.answer"
          :id="`dinamis-form-${idx}`"
        />
        <QAnswerNumber
          ref="answer"
          v-else-if="item.answerType === 'number'"
          :key="`number-${item.id}`"
          :question="item.question"
          :is-required="item.isRequired"
          :subtext="item.subtext"
          v-model="item.answer"
          :id="`dinamis-form-${idx}`"
        />
        <QAnswerDate
          ref="answer"
          v-else-if="item.answerType === 'date_picker'"
          :key="`date-${item.id}`"
          :question="item.question"
          :is-required="item.isRequired"
          :subtext="item.subtext"
          v-model="item.answer"
          :id="`dinamis-form-${idx}`"
        />
        <QAnswerRadio
          ref="answer"
          v-else-if="item.answerType === 'checkboxes'"
          :key="`radio-${item.id}`"
          :question="item.question"
          :selections="item.answerContent"
          :is-required="item.isRequired"
          :subtext="item.subtext"
          v-model="item.answer"
          :id="`dinamis-form-${idx}`"
        />
        <QAnswerCheckbox
          ref="answer"
          v-else-if="item.answerType === 'multiple_choice'"
          :key="`checkbox-${item.id}`"
          :question="item.question"
          :selections="item.answerContent"
          :is-required="item.isRequired"
          :subtext="item.subtext"
          v-model="item.answer"
          :id="`dinamis-form-${idx}`"
        />
        <QParagraphAnswer
          ref="answer"
          v-else-if="item.answerType === 'paragraph'"
          :key="`paragraph-${item.id}`"
          :question="item.question"
          :is-required="item.isRequired"
          :subtext="item.subtext"
          v-model="item.answer"
          :id="`dinamis-form-${idx}`"
        />
        <c-box :key="item.id" v-else>ELESE {{ item.question }} {{ item.answerType }}</c-box>
      </template>
    </template>
    <template v-else>
      <pre v-if="isDevelopment">
        No additionals questions
      </pre>
    </template>
  </c-box>
</template>

<script >
import _ from "lodash";
import { computed, ref, watch } from "@vue/composition-api";
import QAnswerDate from "@/components/quizionary/date-answer.vue";
import QShortAnswer from "@/components/quizionary/short-answer.vue";
import QAnswerDropdown from "@/components/quizionary/dropdown.vue";
import QAnswerNumber from "@/components/quizionary/number-answer.vue";
import QAnswerCheckbox from "@/components/quizionary/checkbox-answer.vue";
import QAnswerRadio from "@/components/quizionary/radio-answer.vue";
import QParagraphAnswer from "@/components/quizionary/paragraph-answer.vue";
import { useEqualWatch } from "../progress-tracker/forms/helper-mixin";
const __sfc_main = {};
__sfc_main.props = ["value"];

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const questions = computed(() => props.value);
  const answers = computed(() => {
    return questions.value.map(it => ({
      questionId: it.id,
      answer: it.answer
    }));
  });
  useEqualWatch(questions, questions => {
    console.log("questions", questions);
  });
  useEqualWatch(answers, _.debounce(answers => {// console.log("answers", answers)
  }), 350);
  return {
    questions
  };
};

__sfc_main.components = Object.assign({
  QShortAnswer,
  QAnswerDropdown,
  QAnswerNumber,
  QAnswerDate,
  QAnswerRadio,
  QAnswerCheckbox,
  QParagraphAnswer
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped></style>
