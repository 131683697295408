<template>
  <c-form-control
    mb="30px"
    :is-required="isRequired"
    :is-invalid="$v.value.$invalid"
  >
    <c-form-label
      fontSize="14px"
      color="#555555"
      fontWeigh="400"
      fontFamily="Roboto"
    >
      {{ question }}
    </c-form-label>
    <c-input-group size="md">
      <c-input
        :placeholder="question"
        height="62px"
        type="date"
        :value="value"
        @input="$emit('input', $event)"
      />
    </c-input-group>
    <c-form-helper-text font-size="12px">
      {{ subtext }}
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import {requiredIf} from "vuelidate/lib/validators";

export default {
  name: "QAnswerDate",
  props: ["question", "isRequired", "value", "subtext"],
  validations: {
    value: { required: requiredIf(function() {
      return this.isRequired;
    })}
  },
}
</script>
