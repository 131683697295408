<template>
  <c-box id="kuisioner">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px" :px="['20px', '0']">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <Breadcrumb
          :items="[
            {
              label: 'Beranda',
              href: $router.resolve({ name: 'client.index' }).href,
            },
            {
              label: 'Kuisioner',
              href: $router.resolve({ name: 'client.kuisioner' }).href,
              isCurrent: true,
            },
          ]"
        />
        <hr v-chakra mt="30px" />
        <Steps :current="6" :total="7" v-chakra mt="30px" />

        <c-box w="100%" maxWidth="590px" mx="auto" marginTop="50px">
          <c-box textAlign="center">
            <c-text
              color="brand.900"
              fontFamily="roboto"
              textTransform="uppercase"
              fontWeight="700"
              fontSize="16px"
              marginBottom="10px"
              >Kuisioner (6/7)
            </c-text>
            <c-heading
              fontWeight="600"
              fontSize="36px"
              lineHeight="54px"
              color="black.900"
              >Kemampuan Aktivitas Fisik
            </c-heading>
          </c-box>
          <form @submit.prevent="submit">
            <c-box
              marginTop="80px"
              v-chakra="{
                'input[type=radio]:checked+.css-1nskzgf': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                'input[type=checkbox]:checked+.css-fd41dq': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                div: {
                  fontSize: '18px',
                },
              }"
            >
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.hasDoctorDiagnose.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Apakah kamu pernah didiagnosa dokter untuk mengurangi
                  aktifitas fisik kecuali yang direkomendasikan dokter?
                </c-form-label>

                <c-stack>
                  <c-radio-group variant-color="brand" v-model="hasDoctorDiagnose">
                    <c-radio :is-disabled="!isEditable" size="lg" value="true"
                      >Ya
                    </c-radio>
                    <c-radio :is-disabled="!isEditable" size="lg" value="false"
                      >Tidak
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text
                  v-if="!$v.hasDoctorDiagnose.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>

              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.hasChestPain.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Apakah kamu pernah merasa nyeri dada saat melakukan aktifitas
                  fisik?
                </c-form-label>

                <c-stack>
                  <c-radio-group variant-color="brand" v-model="hasChestPain">
                    <c-radio :is-disabled="!isEditable" size="lg" value="true"
                      >Ya
                    </c-radio>
                    <c-radio :is-disabled="!isEditable" size="lg" value="false"
                      >Tidak
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text
                  v-if="!$v.hasChestPain.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.hasLostBalance.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Apakah kamu pernah merasa kehilangan keseimbangan karena
                  pusing sampai kehilangan kesadaran/pingsan?
                </c-form-label>

                <c-stack>
                  <c-radio-group variant-color="brand" v-model="hasLostBalance">
                    <c-radio :is-disabled="!isEditable" size="lg" value="true"
                      >Ya
                    </c-radio>
                    <c-radio :is-disabled="!isEditable" size="lg" value="false"
                      >Tidak
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text
                  v-if="!$v.hasLostBalance.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.hasBoneProblem.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Apakah kamu mempunyai masalah tulang atau persendian yang
                  menjadi lebih parah ketika melakukan aktivitas fisik
                </c-form-label>

                <c-stack>
                  <c-radio-group variant-color="brand" v-model="hasBoneProblem">
                    <c-radio :is-disabled="!isEditable" size="lg" value="true"
                      >Ya
                    </c-radio>
                    <c-radio :is-disabled="!isEditable" size="lg" value="false"
                      >Tidak
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text
                  v-if="!$v.hasBoneProblem.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.isOnTreatment.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Apakah saat ini kamu sedang dalam pengobatan/minum obat untuk
                  tekanan darah tinggi atau penyakit jantung?
                </c-form-label>

                <c-stack>
                  <c-radio-group variant-color="brand" v-model="isOnTreatment">
                    <c-radio :is-disabled="!isEditable" size="lg" value="true"
                      >Ya
                    </c-radio>
                    <c-radio :is-disabled="!isEditable" size="lg" value="false"
                      >Tidak
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text
                  v-if="!$v.isOnTreatment.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.hasOtherCondition.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Selain poin diatas, apakah kamu mempunyai kondisi tertentu
                  yang membatasi untuk beraktifitas fisik?
                </c-form-label>

                <c-stack>
                  <c-radio-group variant-color="brand" v-model="hasOtherCondition">
                    <c-radio :is-disabled="!isEditable" size="lg" value="true"
                      >Ya
                    </c-radio>
                    <c-radio :is-disabled="!isEditable" size="lg" value="false"
                      >Tidak
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text
                  v-if="!$v.hasOtherCondition.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>

              <DinamisForm v-model="questions" />

              <c-flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="30px"
              >
                <c-button
                  left-icon="chevron-left"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  as="router-link"
                  :to="{ name: 'client.kuisioner5', params: $route.params }"
                >
                  Kembali
                </c-button>
                <c-button
                  right-icon="chevron-right"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  type="submit"
                >
                  Selanjutnya
                </c-button>
              </c-flex>
            </c-box>
          </form>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import Header from "@/components/header/index.vue"
import Footer from "@/components/Footer.vue"
import { mapState } from "vuex"
import { required } from "vuelidate/lib/validators"
import Breadcrumb from "@/components/breadcrumb"
import Steps from "@/components/steps"
import DinamisForm from "@/views/client/kuisioner/dinamis-form"

export default {
  name: "kuisioner6",
  components: {
    Header,
    Footer,
    Breadcrumb,
    Steps,
    DinamisForm,
  },
  data() {
    return {
      hasDoctorDiagnose: null,
      hasChestPain: null,
      hasLostBalance: null,
      hasBoneProblem: null,
      isOnTreatment: null,
      hasOtherCondition: null,
      // -- dinamis form
      questions: [],
    }
  },
  validations: {
    hasDoctorDiagnose: { required },
    hasChestPain: { required },
    hasLostBalance: { required },
    hasBoneProblem: { required },
    isOnTreatment: { required },
    hasOtherCondition: { required },
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.physicalActivityAbilities,
    }),
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    value() {
      return {
        hasDoctorDiagnose: this.hasDoctorDiagnose === "true",
        hasChestPain: this.hasChestPain === "true",
        hasLostBalance: this.hasLostBalance === "true",
        hasBoneProblem: this.hasBoneProblem === "true",
        isOnTreatment: this.isOnTreatment === "true",
        hasOtherCondition: this.hasOtherCondition === "true",
      }
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    kuisData: {
      immediate: true,
      handler(val) {
        if (val == null) return

        this.hasDoctorDiagnose = val.hasDoctorDiagnose?.toString()
        this.hasChestPain = val.hasChestPain?.toString()
        this.hasLostBalance = val.hasLostBalance?.toString()
        this.hasBoneProblem = val.hasBoneProblem?.toString()
        this.isOnTreatment = val.isOnTreatment?.toString()
        this.hasOtherCondition = val.hasOtherCondition?.toString()
      },
    },
    programId: {
      immediate: true,
      async handler(programId) {
        if (programId == null) return;

        await this.$store.dispatch("kuisioner/getV2ClientForCategory", "physical_activity_abilities")
        await this.$store.dispatch("kuisioner/getV2ClientDraft", programId)
        this.questions = this.$store.getters["kuisioner/questionFor"]("physical_activity_abilities")
      }
    },
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      await this.$store.dispatch("kuisioner/setPhysicalActivityAbilities", {
        programId: this.$route.params.programId,
        value: this.value,
        answers: this.answers,
      })
      await this.$router.push({
        name: "client.kuisioner7",
        params: this.$route.params,
      })
    },
  },
}
</script>

<style></style>
