<template>
  <c-form-control mb="30px" :is-required="isRequired" :is-invalid="$v.value.$invalid">
    <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
    >
      {{ question }}
    </c-form-label>
    <c-checkbox-group
        size="lg"
        variant-color="green"
        spacing="2"
        v-model="value__"
        :required="isRequired"
    >
      <c-checkbox v-for="selection in selections" :key="selection.id" :value="selection.answer">
        {{selection.answer}}
      </c-checkbox>
    </c-checkbox-group>
    <c-form-helper-text font-size="12px">
      {{ subtext }}
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import {requiredIf} from "vuelidate/lib/validators";

export default {
  name: "checkbox-answer",
  props: ["question", "isRequired", "selections", "value", "subtext"],
  computed: {
    value__: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  validations: {
    value: {
      required: requiredIf(function () {
        return this.isRequired;
      }),
    },
  },
}
</script>

<style scoped>

</style>
